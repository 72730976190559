import React from 'react';
import Content from '../views/Fleet/Price';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Fleet management pricing - How much would it cost?',
    lang: 'en',
    description: 'What does a fleet management solution cost? Fill out the form to get a specific price for fleet management service.',
    meta: [{
      name: 'keywords',
      content: 'Fleet management pricing'
    }]
  }}/>
);